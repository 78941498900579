<template>
  <div>
    <div class="page-name">
      <span>
        <i class="fas fa-map-marker-alt"></i>
        {{i18n('Menu.MenuManagement')}}
      </span>
    </div>

    <Table />
  </div>
</template>

<script>
import Table from './Table.vue';

export default {
  components: {
    Table,
  },
  methods: {
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },
  }
};
</script>
