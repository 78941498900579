<template>
  <div class="menuTable">
    <!-- <button
      v-if="scope.row._level === 1"
      class="btn btn-success btn-sm mr-2"
      @click="openEditModel(null, 'new')"
    >
      新增
    </button> -->
    <VueTreeGrid :columns="columns" :tree="tree">
      <!-- <template slot="permission--row-cell" slot-scope="scope">
                <button class="btn btn-info btn-sm">
                    權限
                </button>
            </template> -->

      <template slot="action--row-cell" slot-scope="scope">
        <!-- <button
          v-if="scope.row._level === 1"
          class="btn btn-success btn-sm mr-2"
          @click="openEditModel(scope.row, 'new')"
        >
          新增
        </button> -->
        <!-- <button
                  v-if="scope.row._level === 2"
                  class="btn btn-primary btn-sm mr-2"
                  @click="openModel(scope.row)"
                  ref="edit"
                >
                    編輯連結
                </button>  -->

        <button
          v-if="scope.row._level === 2"
          class="btn btn-primary btn-sm mr-2"
          @click="openEditModel(scope.row)"
          ref="edit"
        >
          {{i18n('Basic.Edit')}}
        </button>

        <button
          class="btn btn-primary btn-sm mr-2"
          @click="getMenuLangList(scope.row)"
          ref="edit"
        >
          {{i18n('Basic.Language')}}
        </button>
        <!-- <button class="btn btn-danger btn-sm" @click="deleteMenu(scope.row)">
          刪除
        </button> -->
      </template>
    </VueTreeGrid>

    <!-- Modal -->
    <div class="modal fade" id="editModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">選單設定</div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="field">
                  <label class="field-name">選單名稱</label>
                  <input
                    v-model="tempMenu.MenuName"
                    data-val="true"
                    data-val-length="欄位 選單名稱 必須是最大長度為 50 的字串。"
                    data-val-length-max="50"
                    data-val-required="選單名稱 欄位是必要項。"
                    id="Name"
                    length="50"
                    name="Name"
                    type="text"
                    value=""
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="field">
                  <label class="field-name">選單連結</label>
                  <input
                    v-model="tempMenu.Link"
                    data-val="true"
                    data-val-length="欄位 選單連結 必須是最大長度為 50 的字串。"
                    data-val-length-max="50"
                    data-val-required="選單連結 欄位是必要項。"
                    id="LinkName"
                    length="50"
                    name="LinkName"
                    type="text"
                    value=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              取消
            </button>
            <button
              type="button"
              class="btn btn-blue"
              data-dismiss="modal"
              @click="editMenu"
            >
              儲存
            </button>
          </div>
        </div>
      </div>
    </div>

    <SettingModal :choiceMenu="choiceMenu" @getMenu="getMenu" />
    <!-- <EditModal /> -->

    <!-- 語系Modal -->
    <div class="modal fade" id="langModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">{{i18n('Basic.LanguageSetting')}}</div>

          <div class="modal-body">
            <el-table :data="menuLangList">
              <el-table-column
                prop="LocalizationTypeName"
                :label="i18n('Basic.LanguageName')"
              ></el-table-column>
              <el-table-column :label="i18n('Menu.MenuName')">
                <template slot-scope="scope">
                  <input class="form-control" v-model="scope.row.MenuName" />
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{i18n('Basic.Cancel')}}
            </button>
            <button
              type="button"
              class="btn btn-blue"
              data-dismiss="modal"
              @click="saveMenuLang"
            >
              {{i18n('Basic.Save')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn-groups {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table th.click {
  cursor: pointer;
}

.icon {
  display: inline-block;
  margin-left: 6px;
}
.icon.inverse {
  transform: rotate(180deg);
}

.show {
  display: flex;
  align-items: center;

  select {
    margin: 0 6px;
    font-size: 0.9em;
    display: block;
    padding: 3px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  p {
    margin-bottom: 0;
  }
}

.entries {
  float: left;
}

.tree-grid__body-row--level-2 .btn-info,
.tree-grid__body-row--level-2 .btn-primary {
  margin-left: -3px;
}
</style>

<script>
// import EditModal from './EditModal'
import { mapActions } from 'vuex';
import VueTreeGrid from '@reallife/vue-tree-grid';
import $ from 'jquery';
import SettingModal from './SettingModal.vue';

export default {
  components: {
    SettingModal,
    // EditModal,
    VueTreeGrid,
  },
  data() {
    return {
      tree: [],
      tempMenu: {},
      choiceMenu: {},
      selected: [],
      selectAll: false,
      // 排序
      sortType: 'name',
      isReverse: false,
      menuIsNew: false,
      menuLangList: [],
    };
  },

  computed: {
     columns(){
      const col =[
        {
          width: '2%',
          label: '',
        },
        {
          prop: 'MenuName',
          label: this.i18n('Menu.MenuName'),
          width: '20%',
        },
        {
          prop: 'Link',
          label: this.i18n('Menu.MenuLink'),
        },
        {
          type: 'prop',
          prop: 'action',
          label: '',
          width: '15%',
        },
      ]
      return col;
     }
  },
  methods: {
    ...mapActions(['updateLoading']),
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },
    changeType(type) {
      const vm = this;
      if (vm.sortType === type) {
        vm.isReverse = !vm.isReverse;
      } else {
        vm.isReverse = false;
      }
      vm.sortType = type;
    },
    getMenu() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Menu`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: '錯誤',
              message: response.data.ErrorMessage,
              type: 'error',
              duration: 0,
            });
          } else {
            const tree = [];
            response.data.Data.forEach((item) => {
              // console.log('item', item)
              const data = {
                ...item,
                children: item.Children,
              };
              tree.push(data);
            });
            this.tree = tree;
          }
          this.screen.close();
        })

        .catch((error) => {
          this.$notify({
            title: '錯誤',
            message: error.response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
          this.screen.close();
        });
    },
    getMenuLangList(item) {
      this.screen = this.openLoading();
      this.choiceMenu = item;
      const url = `${window.BaseUrl.api}/Menu/locale/${item.Guid}`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: '錯誤',
              message: response.data.ErrorMessage,
              type: 'error',
              duration: 0,
            });
          } else {
            this.menuLangList = response.data.Data;
          }
          this.screen.close();
          $('#langModal').modal('show');
        })

        .catch((error) => {
          this.$notify({
            title: '錯誤',
            message: error.response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
          this.screen.close();
        });
    },
    openModel(item) {
      // console.log('item', item)
      this.tempMenu = {
        ...item,
      };
      $('#editModal').modal('show');
    },
    editMenu() {
      const url = `${window.BaseUrl.api}/menu`;

      this.updateLoading(true);

      const obj = {
        UpdateUser: localStorage.EmpCName,
        ...this.tempMenu,
      };

      this.$http.put(url, obj)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: '錯誤',
              message: response.data.ErrorMessage,
              type: 'error',
              duration: 0,
            });
          } else {
            this.getMenu();
          }
          this.updateLoading(false);
        })
        .catch((error) => {
          this.$notify({
            title: '錯誤',
            message: error.response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
          this.screen.close();
        });
    },
    saveMenuLang() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Menu/locale/${this.choiceMenu.Guid}`;
      const params = this.menuLangList;
      this.$http
        .post(url, params)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: '錯誤',
              message: response.data.ErrorMessage,
              type: 'error',
              duration: 0,
            });
          } else {
            this.$notify({
              title: '成功',
              message: '儲存成功',
              type: 'success',
              duration: 0,
            });
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: '錯誤',
            message: error.response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
          this.screen.close();
        });
    },
    deleteMenu(item) {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Menu/${item.Guid}`;

      this.$http.delete(url).then((response) => {
        if (response.data.ErrorMessage) {
          this.$notify({
            title: '錯誤',
            message: response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
        } else {
          this.getMenu();
        }
        this.screen.close();
      });
    },
    openEditModel(item, type) {
      this.choiceMenu = item;
      if (type === 'new') {
        this.menuIsNew = true;
      }

      $('#settingModal').modal('show');
    },
  },
  created() {
    this.getMenu();
  },
};
</script>
<style lang="scss">
i.tree-grid__expand-icon {
  background-color: #4062c2 !important;
  border-radius: 50% !important;
  color: #fff;
  line-height: 17px !important;
  width: 20px !important;
  height: 20px !important;
  margin-left: -20px;
}

.tree-grid__wrapper .tree-grid__table {
  background-color: #fff !important;
}

i.tree-grid__expand-icon.tree-grid__expand-icon--expanded {
  background-color: #e55353 !important;
}

.tree-grid__table th {
  // background-color: #303c50;
  background-color: #fff;
  // color: #fff;
}
</style>
