<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="settingModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">{{i18n('Custom.RoleCorrespondence')}}</div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="field">
                  <span class="field-name">{{i18n('Role.Role')}}</span>
                  <div class="check-float-wrap">
                    <label
                      class="checkbox"
                      v-for="item in items"
                      :key="item.Id"
                    >
                      <input
                        type="checkbox"
                        :value="item.Id"
                        v-model="choiceRole"
                      />
                      <span>{{ item.Name }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{i18n('Basic.Cancel')}}
            </button>
            <button
              type="button"
              class="btn btn-blue"
              data-dismiss="modal"
              @click="editRoleMenu"
            >
              {{i18n('Basic.Save')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn-blue {
  color: #fff !important;
  font-size: 0.85em;
  background: #343b55;
  height: 38px;
}

.checkbox {
  margin: 0 0.5rem;
}
</style>

<script>
import { mapActions } from 'vuex';

export default {
  props: ['choiceMenu'],
  data() {
    return {
      items: [],
      choiceRole: [],
    };
  },
  methods: {
    ...mapActions(['updateLoading']),
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },
    getMenuRole() {
      const url = `${window.BaseUrl.api}/Menu/RoleByMenuId`;

      const params = {
        menuGuid: this.choiceMenu.Guid,
      };

      this.$http.get(url, { params }).then((response) => {
        if (response.data.ErrorMessage) {
          this.$notify({
            title: '錯誤',
            message: response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
        } else {
          this.choiceRole = response.data.Data.map((role) => role.Id);
        }
      });
    },
    editRoleMenu() {
      const url = `${window.BaseUrl.api}/Menu/MenuRoles/?menuGuid=${this.choiceMenu.Guid}`;


      this.updateLoading(true);
      this.$http
        .put(url, this.choiceRole)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: '錯誤',
              message: response.data.ErrorMessage,
              type: 'error',
              duration: 0,
            });
          } else {
            this.$emit('getMenu');
            this.$store.dispatch('alertMessageModule/updateMessage', {
              message: '成功編輯角色',
              status: 'success',
            });
          }
          this.updateLoading(false);
        })
        .catch(() => {
          this.updateLoading(false);
          this.$store.dispatch('alertMessageModule/updateMessage', {
            message: '新增角色失敗',
            status: 'danger',
          });
        });
    },
    getAllData() {
      const vm = this;
      const url = `${window.BaseUrl.api}/role`;

      vm.$http.get(url).then((response) => {
        if (response.status === 200) {
          vm.items = response.data.Data;
        }
      });
    },
  },
  watch: {
    choiceMenu() {
      this.getMenuRole();
      this.getAllData();
    },
  },
};
</script>
